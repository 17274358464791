import { useEffect, useState } from "react";
import { getTenant, isFreeStack } from "./tenant";
import { useQuery } from "react-query";
import { getCreditUsage, getUserList } from "./backend_helper";
import { GET_USER_LIST } from "../services/constants";
import { getUserById } from "./utils";

export const useBoolean = (defaultValue = false) => {
  const [value, setValue] = useState(defaultValue);
  return {
    value,
    toggle: () => setValue((v) => !v),
    setTrue: () => setValue(true),
    setFalse: () => setValue(false),
  };
};

export const useUsers = (allUsers = false) => {
  const withServiceUser = false;
  const tenant = getTenant();
  const { data: users } = useQuery({
    queryKey: [GET_USER_LIST, tenant, withServiceUser, allUsers],
    queryFn: () => getUserList(tenant, withServiceUser, allUsers),
    cacheTime: 300000,
  });
  return { users, getById: (id) => getUserById(users, id) };
};

export const useUsersWithServiceUser = () => {
  const withServiceUser = true;
  const tenant = getTenant();
  const { data: users } = useQuery({
    queryKey: [GET_USER_LIST, tenant, withServiceUser],
    queryFn: () => getUserList(tenant, withServiceUser),
    cacheTime: 300000,
  });
  return { users, getById: (id) => getUserById(users, id) };
};

export const useUsersWithServiceUserWithMapping = () => {
  const withServiceUser = true;
  const tenant = getTenant();

  const { data: users } = useQuery({
    queryKey: [GET_USER_LIST, tenant, withServiceUser],
    queryFn: () => getUserList(tenant, withServiceUser),
    cacheTime: 300000,
  });

  const filteredUsers =
    users?.filter((user) => user.first_name && user.last_name) || [];

  const userNameToID = filteredUsers.reduce((map, user) => {
    map[`${user.first_name} ${user.last_name}`] = user.id;
    return map;
  }, {});

  const userIDtoName = filteredUsers.reduce((map, user) => {
    map[user.id] = `${user.first_name} ${user.last_name}`;
    return map;
  }, {});

  return {
    users: filteredUsers,
    getById: (id) => getUserById(filteredUsers, id),
    userNameToID,
    userIDtoName,
  };
};

export const useIsEnterpriseUser = () => {
  const isFreeUser = isFreeStack();
  const { data } = useQuery({
    queryFn: getCreditUsage,
    enabled: isFreeUser,
  });
  if (!isFreeUser) return { isEnterpriseUser: true };
  return { isEnterpriseUser: data?.current_edition === "Enterprise" };
};

const SESSION_KEY = "sessionId";
const EXPIRY_KEY = "sessionExpiry";
const SESSION_DURATION = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
const RENEWAL_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds

function generateSessionId() {
  return "_" + Math.random().toString(36).substr(2, 9) + "_saas";
}

export function useSession() {
  const [sessionId, setSessionId] = useState(() => {
    const storedSessionId = localStorage.getItem(SESSION_KEY);
    const storedExpiry = localStorage.getItem(EXPIRY_KEY);
    if (
      storedSessionId &&
      storedExpiry &&
      new Date().getTime() < parseInt(storedExpiry, 10)
    ) {
      return storedSessionId;
    }
    return generateNewSession();
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const storedExpiry = localStorage.getItem(EXPIRY_KEY);
      if (
        storedExpiry &&
        new Date().getTime() > parseInt(storedExpiry, 10) - RENEWAL_THRESHOLD
      ) {
        generateNewSession();
      }
    }, RENEWAL_THRESHOLD);

    return () => clearInterval(interval);
  }, []);

  function generateNewSession() {
    const newSessionId = generateSessionId();
    const newExpiry = new Date().getTime() + SESSION_DURATION;
    localStorage.setItem(SESSION_KEY, newSessionId);
    localStorage.setItem(EXPIRY_KEY, newExpiry.toString());
    setSessionId(newSessionId);
    return newSessionId;
  }

  return sessionId;
}
