import { z } from "zod";

export interface QueryGroup {
  annualized_cost: string;
  avg_cost: string;
  avg_exec_time: string;
  distinct_users: number;
  distinct_warehouses: number;
  full_query: string | null;
  query_hash: string;
  tags: string[];
  total_cost: string;
  total_run: number;
  trimmed_query: string;
  users: string[];
  warehouses: string[];
}

export interface QueryTextResponse {
  query_text: string;
}

export const QueryTagsSchema = z.object({
  name: z.string(),
  description: z.string(),
  cost_savings: z.boolean(),
  time_savings: z.boolean(),
}
);
export interface QueryTags extends z.infer<typeof QueryTagsSchema> {}

export const QueryFiltersSchema = z.object({
  tags: z.array(QueryTagsSchema),
  users: z.array(z.string()),
  warehouses: z.array(z.string()),
  error_code: z.string().nullable(),
  message: z.string().nullable(),
  ok: z.boolean(),
});

export interface QueryFilters extends z.infer<typeof QueryFiltersSchema> {}

export const QueryItemSchema = z.object({
  id: z.string(),
  query_id: z.string(),
  query_type: z.string(),
  query_hash: z.string(),
  query_parameterized_hash: z.string(),
  bytes_scanned: z.string(),
  session_id: z.string(),
  query_text: z.string().nullable(),
  query_trimmed: z.string(),
  query_name: z.string().nullable(),
  query_cost: z.string(),
  execution_time: z.string(),
  insights: z.array(z.string()),
  start_time: z.string(),
  end_time: z.string(),
  warehouse_name: z.string(),
  user_name: z.string(),
  warehouse_size: z.string(),
  partitions_scanned: z.string(),
  partitions_total: z.string(),
  percentage_scanned_from_cache: z.string(),
  bytes_spilled_to_local_storage: z.string(),
  bytes_spilled_to_remote_storage: z.string(),
  bytes_sent_over_network: z.string().nullable(),
  queued_overload_time: z.string(),
  queued_provisioning_time: z.string().nullable(),
  queued_repair_time: z.string().nullable(),
  query_load_percent: z.string(),
  data_store_id: z.string().nullable(),
  datasets: z.string().nullable(),
});

export interface QueryItem extends z.infer<typeof QueryItemSchema> {}

export const getSnowflakeJobItemSchema = z.object({
  apps: z.array(z.string()),
  bytes_scanned: z.number(),
  execution_time: z.number(),
  last_runtime: z.number(),
  max_runtime: z.number(),
  min_runtime: z.number(),
  query_cost: z.number(),
  runs: z.number(),
  warehouse_names: z.array(z.string()),
  teams: z.array(z.string()),
  workflow_id: z.string(),
});

export const getSnowflakeJobSummarySchema = z.object({
  workflow_id: z.string(),
  query_cost: z.number(),
  bytes_scanned: z.number(),
  execution_time: z.number(),
  percentile_90_runtime: z.number(),
  last_runtime: z.number(),
  max_runtime: z.number(),
  min_runtime: z.number(),
  runs: z.number(),
  success_runs: z.number(),
  warehouse_names: z.array(z.string()),
  teams: z.array(z.string()),
  apps: z.array(z.string()),
});

export const getSnowflakeJobRunItemSchema = z.object({
  run_id: z.string(),
  query_cost: z.number(),
  bytes_scanned: z.number(),
  execution_time: z.number(),
  success_count: z.number(),
  total_count: z.number(),
  timestamp: z.string(),
  start_time: z.string(),
  warehouses: z.array(z.string()),
  execution_statuses: z.array(z.string()),
  query_parameterized_hashes: z.array(z.string()),
  annotation: z.nullable(z.array(z.string())),
});

export const getSnowflakeJobRunGraphDataSchema = z.object({
  run_id: z.string(),
  timestamp: z.string(),
}).catchall(z.number());

export const getSnowflakeJobGraphDataSchema = z.object({
  date: z.string(),
}).catchall(z.number());

export const getSnowflakeJobTaskItemSchema = z.object({
  task_id: z.string(),
  query_id: z.string(),
  hash: z.string(),
  query_cost: z.number(),
  bytes_scanned: z.number(),
  execution_time: z.number(),
  start_time: z.string(),
  end_time: z.string(),
  execution_status: z.string(),
});

export const snowflakeJobSettingsSchema = z.object({
  prefix: z.string().nullable(),
  workflow_id: z.string(),
  run_id: z.string(),
  task_id: z.string(),
  project_id: z.string(),
  app_id: z.string(),
});

export const snowflakeJobFiltersSchema = z.object({
  workflow_ids: z.array(z.string()),
  warehouses: z.array(z.string()),
  teams: z.array(z.string()),
  apps: z.array(z.string()),
})

// Inferring Types
export type GetSnowflakeJobItem = z.infer<typeof getSnowflakeJobItemSchema>;
export type GetSnowflakeJobSummary = z.infer<typeof getSnowflakeJobSummarySchema>;
export type GetSnowflakeJobRunItem = z.infer<typeof getSnowflakeJobRunItemSchema>;
export type GetSnowflakeJobRunGraphData = z.infer<typeof getSnowflakeJobRunGraphDataSchema>;
export type GetSnowflakeJobGraphData = z.infer<typeof getSnowflakeJobGraphDataSchema>;
export type GetSnowflakeJobTaskItem = z.infer<typeof getSnowflakeJobTaskItemSchema>;
export type SnowflakeJobSettings = z.infer<typeof snowflakeJobSettingsSchema>;
export type SnowflakeJobFilters = z.infer<typeof snowflakeJobFiltersSchema>;

export const QueryGroupsFiltersSchema = z.object({
  page: z.number().optional(),
  size: z.number().optional(),
  sort_key: z.string().optional(),
  sort_order: z.string().optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  tags: z.array(z.string()).optional(),
  users: z.array(z.string()).optional(),
  warehouses: z.array(z.string()).optional(),
  query_hash: z.string().optional(),
});

export type QueryGroupsFilters = z.infer<typeof QueryGroupsFiltersSchema>;

export const QueryJobsFiltersSchema = z.object({
  page: z.number().optional(),
  size: z.number().optional(),
  sort_key: z.string().optional(),
  sort_order: z.string().optional(),
  warehouses: z.array(z.string()).optional(),
  auto_tune: z.boolean().optional(),
  environments: z.array(z.string()).optional(),
  annualized_cost: z.string().optional(),
  job_id: z.string().optional(),
});

export type QueryJobsFilters = z.infer<typeof QueryJobsFiltersSchema>;

export const QueryJobExecutionsFiltersSchema = z.object({
  page: z.number().optional(),
  size: z.number().optional(),
  sort_key: z.string().optional(),
  sort_order: z.string().optional(),
  executionCostFilter: z.string().optional(),
  warehouses: z.array(z.string()).optional(),
  warehouse_sizes: z.array(z.string()).optional(),
  users: z.array(z.string()).optional(),
});

export type QueryJobExecutionsFilters = z.infer<
  typeof QueryJobExecutionsFiltersSchema
>;


export const QueryAllCSVFiltersSchema = z.object({
  page: z.number().optional(),
  size: z.number().optional(),
  query_hash: z.string().optional(),
  query_parameterized_hash: z.string().optional(),
  query_id: z.string().optional(),
  warehouses: z.array(z.string()).optional(),
  tags: z.array(z.string()).optional(),
  users: z.array(z.string()).optional(),
  sortAttribute: z.string().optional(),
  sortOrder: z.string().optional(),
  executionTimeFilter: z.string().optional(),
  executionTimeFilterMode: z.string().optional(),
  queryCostFilter: z.string().optional(),
  queryCostFilterMode: z.string().optional(),
  session_id: z.string().optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
});

export type QueryAllCSVFilters = z.infer<typeof QueryAllCSVFiltersSchema>;
