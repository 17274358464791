import { UnknownAction } from "@reduxjs/toolkit";
import { z } from "zod";
import { PaginationSchema } from "@/Components/Paginate/types";

export type EntityType = "rule" | "user" | "usergroup";

export enum UserGroupEntities {
  USER = "user",
  ROLE = "role",
  WAREHOUSE = "warehouse",
  DATABASE = "database",
  SCHEMA = "schema",
}

export enum UserGroupRuleTypes {
  EXACT = "exact",
  PREFIX = "starts_with",
  SUFFIX = "ends_with",
  CONTAINS = "contains",
}

export const UserGroupRulesZodSchema = z.object({
  id: z.coerce.string(),
  entity_type: z.nativeEnum(UserGroupEntities),
  rule: z.object({
    entity: z.string(),
    type: z.nativeEnum(UserGroupRuleTypes),
  }),
  created_at: z.string(),
  created_by: z.string(),
  updated_at: z.string(),
  updated_by: z.string(),
});
export interface UserGroupRules
  extends z.infer<typeof UserGroupRulesZodSchema> {}

export const UserGroupZodSchema = z.object({
  id: z.coerce.string(),
  uuid: z.string(),
  name: z.string(),
  description: z.string(),
  symbol: z.string().optional(),
  is_owner: z.boolean().optional(),
  created_at: z.string(),
  created_by: z.coerce.string(),
  updated_at: z.string(),
  updated_by: z.coerce.string(),
});

export interface UserGroup extends z.infer<typeof UserGroupZodSchema> {}

export const UserGroupWithMembersAndRulesZodSchema = z.object({
  ...UserGroupZodSchema.shape,
  users: z.array(
    z.object({
      id: z.coerce.string(),
      uuid: z.string(),
      first_name: z.string(),
      last_name: z.string(),
      email: z.string(),
    })
  ),
  user_group_rules: z.array(UserGroupRulesZodSchema),
});
export interface UserGroupWithMembersAndRules
  extends z.infer<typeof UserGroupWithMembersAndRulesZodSchema> {}

export interface UserGroupsStateProps {
  selectedUserGroup?: string;
  userGroups: UserGroup[];
}

export interface ContextProps {
  state: UserGroupsStateProps;
  dispatch: React.Dispatch<UnknownAction>;
}

export const GetAllUserGroupsResponseSchema = z.object({
  ...PaginationSchema.shape,
  items: z.array(UserGroupZodSchema),
});

export interface UploadUserResponse {
  ok: boolean;
  failures: { email: string; group_name: string; reason: string }[];
  success: { email: string; group_name: string }[];
}

export interface UploadUserGroupResponse {
  ok: boolean;
  failures: {
    entity_type: string;
    group_name: string;
    reason: string;
    match_type: string;
  }[];
  success: {
    entity_type: string;
    group_name: string;
    match_type: string;
    description: string;
    entity: string;
  }[];
}
