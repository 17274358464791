import axios, { AxiosRequestConfig } from "axios";
import { getUser } from "../services/auth";
import { API_URL, ENV } from "../config";
import {
  z,
  ZodArray,
  ZodDefault,
  ZodNullable,
  ZodObject,
  ZodTypeAny,
} from "zod";

axios.defaults.baseURL = API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
// Added try catch for storybook
try {
  const user = getUser();
  if (user && user.token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  }
} catch (e) {
  console.error(e);
}

const streamHeaders: Record<string, string> = {};

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = error?.response?.data?.detail || "Invalid credentials";
        break;
      case 404:
        message = `Sorry! the data you are looking for could not be found. ${
          error.response?.data?.detail
            ? `Details: ${error.response?.data?.detail}`
            : ""
        }`;
        break;
      default:
        message = error.response?.data?.detail || error?.message || error;
    }
    return Promise.reject(message);
  }
);

const setUserGroupHeader = (userGroupId?: string) => {
  console.log("Setting team header...", userGroupId);
  if (userGroupId && userGroupId !== "-1") {
    axios.defaults.headers.common["x-user-group-id"] = userGroupId;
    streamHeaders["x-user-group-id"] = userGroupId;
  } else {
    delete axios.defaults.headers.common["x-user-group-id"];
    delete streamHeaders["x-user-group-id"];
  }
};

const setAuthorizationHeader = (authToken: string) => {
  console.log("Setting authorization header...");
  if (authToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
    streamHeaders["Authorization"] = "Bearer " + authToken;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    delete streamHeaders["Authorization"];
  }
};

const setTimezoneHeader = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log("Setting timezone header...");
  if (timezone) {
    axios.defaults.headers.common["x-timezone"] = timezone;
    streamHeaders["x-timezone"] = timezone;
  } else {
    delete axios.defaults.headers.common["x-timezone"];
    delete streamHeaders["x-timezone"];
  }
};

const setTenantHeader = (tenant: string) => {
  console.log("Setting tenant...");
  if (tenant) {
    axios.defaults.headers.common["x-tenant"] = tenant;
    streamHeaders["x-tenant"] = tenant;
  } else {
    delete axios.defaults.headers.common["x-tenant"];
    delete streamHeaders["x-tenant"];
  }
};

const checkSecureConnection = () => {
  if (ENV === "staging" || ENV === "production") {
    if (
      location.protocol !== "https:" ||
      API_URL?.substring(0, 5) !== "https"
    ) {
      return false;
    }
  }
  return true;
};

const applyCatchToSchema = <T extends ZodTypeAny>(
  schema: T,
  defaultValue = "test"
): T => {
  if (schema instanceof ZodObject) {
    const shape = schema.shape; // Access the shape correctly
    const newShape = Object.fromEntries(
      Object.entries(shape).map(([key, value]) => [
        key,
        applyCatchToSchema(value as ZodTypeAny, defaultValue),
      ])
    );
    return new ZodObject({
      ...schema._def,
      shape: () => newShape,
    }) as unknown as T;
  } else if (schema instanceof ZodArray) {
    return new ZodArray({
      ...schema._def,
      type: applyCatchToSchema(schema._def.type, defaultValue),
    }) as unknown as T;
  } else if (schema instanceof ZodNullable || schema instanceof ZodDefault) {
    return schema.catch("") as unknown as T;
  } else if (
    schema._def.typeName === "ZodString" ||
    schema._def.typeName === "ZodNumber"
  ) {
    schema._def.defaultValue = undefined;
    // Applies .catch to string and number schemas
    return schema.catch("") as unknown as T;
  }

  return schema;
};

const patchWithZodSchema = <R>(
  response: any,
  endpoint: string,
  zodSchema?: z.ZodObject<any> | z.ZodArray<any>
) => {
  if (!zodSchema) return response as R;
  try {
    const validationResult = zodSchema.safeParse(response);
    if (validationResult.success) {
      return validationResult.data as R;
    }
    console.error(
      "zod validation error",
      endpoint,
      validationResult.error,
      response
    );

    const formattedResponse = applyCatchToSchema(zodSchema).parse(
      response
    ) as R;
    return formattedResponse;
  } catch (e) {
    console.error("patchWithZodSchema", endpoint, e, response);
    return response as R;
  }
};

class APIClient {
  controller?: AbortController;
  /**
   * Fetches data from given url
   */

  get = async <R>(
    url: string,
    params: unknown,
    timeout = 0,
    zodSchema?: z.ZodObject<any> | z.ZodArray<any>,
    config?: AxiosRequestConfig
  ) => {
    if (!checkSecureConnection()) {
      return;
    }
    this.controller = new AbortController();
    const defaultConfig: AxiosRequestConfig = {
      signal: this.controller.signal,
    };
    if (timeout) {
      defaultConfig.signal = AbortSignal.timeout(timeout);
    }
    const finalConfig: AxiosRequestConfig = {
      ...defaultConfig,
      ...config,
      headers: {
        ...axios.defaults.headers.common, // Include global headers
        ...(config?.headers || {}), // Overwrite with explicitly passed headers
      },
    };
    if (!params) return axios.get(`${url}`, finalConfig) as R;
    const paramKeys = [];
    for (const k in params) {
      const v = (params as Record<string, string | number | boolean>)[k];
      if (!Array.isArray(v)) {
        paramKeys.push(`${k}=${encodeURIComponent(v)}`);
        continue;
      }
      for (const i of v) {
        paramKeys.push(`${k}=${encodeURIComponent(i)}`);
      }
    }

    return patchWithZodSchema<R>(
      await axios.get<R>(`${url}?${paramKeys.join("&")}`, {
        ...params,
        ...finalConfig,
        signal: this.controller.signal,
      }),
      url,
      zodSchema
    );
  };
  /**
   * post given data to url
   */
  post = async <R>(
    url: string,
    data: unknown,
    zodSchema?: z.ZodObject<any> | z.ZodArray<any>,
    config?: AxiosRequestConfig
  ): Promise<R | undefined> => {
    if (!checkSecureConnection()) {
      return;
    }
    this.controller = new AbortController();
    return patchWithZodSchema<R>(
      await axios.post(url, data, {
        ...config,
        signal: this.controller.signal,
      }),
      url,
      zodSchema
    );
  };

  postWithParams = async <R>(
    url: string,
    params: Record<string, unknown>,
    data: Record<string, unknown>,
    zodSchema?: z.ZodObject<any> | z.ZodArray<any>
  ) => {
    if (!checkSecureConnection()) {
      return;
    }
    this.controller = new AbortController();
    return patchWithZodSchema<R>(
      await axios.post(url, data, { params, signal: this.controller.signal }),
      url,
      zodSchema
    );
  };
  /**
   * Updates data
   */
  update = async <R>(
    url: string,
    data: Record<string, unknown>,
    zodSchema?: z.ZodObject<any> | z.ZodArray<any>
  ) => {
    if (!checkSecureConnection()) {
      return;
    }
    return patchWithZodSchema<R>(
      await axios.patch(url, data, { signal: this.controller?.signal }),
      url,
      zodSchema
    );
  };

  put = async <R>(
    url: string,
    data: Record<string, unknown>,
    zodSchema?: z.ZodObject<any>
  ) => {
    if (!checkSecureConnection()) {
      return;
    }
    return patchWithZodSchema<R>(
      await axios.put(url, data, { signal: this.controller?.signal }),
      url,
      zodSchema
    );
  };
  /**
   * Delete
   */
  delete = async <R>(
    url: string,
    config: Record<string, unknown>,
    zodSchema?: z.ZodObject<any>
  ) => {
    if (!checkSecureConnection()) {
      return;
    }
    return patchWithZodSchema<R>(
      await axios.delete(url, { ...config, signal: this.controller?.signal }),
      url,
      zodSchema
    );
  };

  cancel = () => {
    this.controller?.abort();
  };
}

async function* getIterableStream(body: ReadableStream<Uint8Array>) {
  const reader = body.getReader();
  const decoder = new TextDecoder();

  while (true) {
    const { value, done } = await reader.read();
    if (done) {
      break;
    }
    const decodedChunk = decoder.decode(value, { stream: true });
    yield decodedChunk;
  }
}

const generateStream = async (
  url: string,
  onProgress: (chunk: string) => void,
  requestBody: Record<string, unknown> | null = null,
  abortController: AbortController | undefined = undefined
) => {
  const requestData: {
    method: "GET" | "POST";
    headers: Record<string, string>;
    body?: string;
    signal?: AbortSignal;
  } = { method: "GET", headers: streamHeaders };
  if (requestBody) {
    requestData.method = "POST";
    requestData.headers["Content-Type"] = "application/json";
    requestData.body = JSON.stringify(requestBody);
  }

  if (abortController) {
    requestData.signal = abortController.signal;
  }

  const response = await fetch(API_URL + url, requestData);
  if (response.status !== 200) throw new Error(response.status.toString());
  if (!response.body) throw new Error("Response body does not exist");

  const stream = await getIterableStream(response.body);
  for await (const chunk of stream) {
    onProgress(chunk);
  }
};

export {
  axios,
  APIClient,
  setAuthorizationHeader,
  setUserGroupHeader,
  setTimezoneHeader,
  setTenantHeader,
  getIterableStream,
  generateStream,
};

export const api = new APIClient();
