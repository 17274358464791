import { getUserGroups } from "@/helpers/apis";
import { useAppDispatch, useAppState } from "@/modules/app/useAppContext";
import { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { UserGroup } from "./types";
import { setUserGroupsLoaded } from "@/modules/app/appSlice";

const useUserGroups = () => {
  const [search, setSearch] = useState("");
  const { rbacEnabled } = useAppState();
  const dispatch = useAppDispatch();

  const query = useInfiniteQuery({
    queryKey: ["UserGroups"],
    queryFn: ({ pageParam }) => getUserGroups({ page: pageParam, search }),
    enabled: rbacEnabled,
    getNextPageParam: (lastPage, _allPages) => {
      if (!lastPage) return undefined;
      const totalPages = lastPage.pages;
      const currentPage = lastPage.page;
      return currentPage >= totalPages ? undefined : currentPage + 1;
    },
  });

  useEffect(() => {
    if (!query.isLoading) {
      dispatch(setUserGroupsLoaded(true));
    }
  }, [dispatch, query.isLoading]);

  const groups = useMemo(() => {
    const result: UserGroup[] = [];
    query.data?.pages.forEach((page) => {
      if (page?.items) {
        result.push(...page.items);
      }
    });
    return result;
  }, [query.data]);

  return {
    ...query,
    search,
    setSearch,
    groups,
    total: query.data?.pages[0]?.total,
  };
};

export default useUserGroups;
