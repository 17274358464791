import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import appSlice, {
  initialState,
  setAuthHeaderSet,
  setLoggedinUser,
} from "./appSlice";
import { ContextProps } from "./types";
import useAppActions from "./useAppActions";
import { RelativeComponentLoader } from "@/Components/Loader";
import { getUser } from "@/services/auth";
import {
  setAuthorizationHeader,
  setTimezoneHeader,
} from "@/helpers/api_helper";

export const AppContext = createContext<ContextProps>({
  state: initialState,
  dispatch: () => null,
});

const AppProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, dispatch] = useReducer(
    appSlice.reducer,
    appSlice.getInitialState()
  );

  const { fetchTenantInfo, loadingTenantInfo, setLoadingTenantInfo } =
    useAppActions(dispatch);

  useEffect(() => {
    const userFromLocalStorage = getUser();
    if (!userFromLocalStorage) {
      setLoadingTenantInfo(false);
      return;
    }
    // if app context does not have loggedin user, set it from local storage
    if (!state.loggedinUser) {
      dispatch(setLoggedinUser(userFromLocalStorage));
      return;
    }

    if (state.loggedinUser) {
      setAuthorizationHeader(state.loggedinUser.auth_token);
      setTimezoneHeader();
      dispatch(setAuthHeaderSet(true));
      fetchTenantInfo();
    }
  }, [state.loggedinUser]);

  const values = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <AppContext.Provider value={values}>
      {loadingTenantInfo ? (
        <RelativeComponentLoader label={"Loading..."} />
      ) : (
        children
      )}
    </AppContext.Provider>
  );
};

export default AppProvider;
