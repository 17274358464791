import { getTenantInfo } from "@/helpers/apis";
import { updateTenantInfo } from "./appSlice";
import { Dispatch, useState } from "react";
import { UnknownAction } from "@reduxjs/toolkit";

const useAppActions = (dispatch: Dispatch<UnknownAction>) => {
  const [loadingTenantInfo, setLoadingTenantInfo] = useState(true);
  const fetchTenantInfo = () => {
    return new Promise<void>((resolve, reject) => {
      setLoadingTenantInfo(true);
      getTenantInfo()
        .then((response) => {
          window.posthog.capture("tenant_info", {
            response,
          });
          if (response) {
            dispatch(updateTenantInfo(response));
          }
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          setLoadingTenantInfo(false);
        });
    });
  };

  return {
    fetchTenantInfo,
    loadingTenantInfo,
    setLoadingTenantInfo,
  };
};

export default useAppActions;
