import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ColumnPickerStateProps } from "./types";

export const initialState = {
  columns: new Set(),
  applicableFor: undefined,
  headers: [],
} as ColumnPickerStateProps;

const columnPickerSlice = createSlice({
  name: "columnPickerState",
  initialState,
  reducers: {
    setTableHeaders: (
      state,
      action: PayloadAction<ColumnPickerStateProps["headers"]>
    ) => {
      state.headers = action.payload;
    },
    setApplicableFor: (
      state,
      action: PayloadAction<ColumnPickerStateProps["applicableFor"]>
    ) => {
      state.applicableFor = action.payload;
    },
    setSelectedColumns: (
      state,
      action: PayloadAction<ColumnPickerStateProps["columns"]>
    ) => {
      state.columns = action.payload;
    },
  },
});

export const { setApplicableFor, setSelectedColumns, setTableHeaders } =
  columnPickerSlice.actions;
export default columnPickerSlice;
