import {
  AssistedSavingsResponse,
  AutoTuneSavingsSummaryResponse,
  GetSummaryOpportunitiesDataResponse,
  OpportunitiesSummary,
  OverallSavingsEstimateResponse,
  OverallSavingsResponse,
  SummaryCostGraphRequest,
} from "../pages/Summary/Context/types";
import {
  FilterResponse,
  GetExportLineageItem,
  GetOpportunitiesDataRequest,
  GetOpportunitiesDataResponse,
  GetOpportunitiesRedirectionRequest,
  GetOpportunitiesRedirectResponse,
  GetOpportunitiesResourceNameFilterRequest,
  GetOpportunitiesResourceRequest,
  GetOpportunitiesResourceResponse,
  GetOpportunitiesSummaryRequest,
  GetOpportunitiesSummaryResponse,
  GetOpportunitiesUserFilterRequest,
  GetOpportunitiesWarehouseFilterRequest,
  GetOpportunityUpdatesRequest,
  GetOpportunityUpdatesResponse,
  GetQuerySQLLineageResponse,
  GroupedOpportunitiesFilters,
  OpportunitiesFilters,
  Page,
  UpdateOpportunitiesDataRequest,
  UpdateOpportunitiesDataResponse,
} from "../pages/Explorer/types";
import {
  GetSnowflakeJobGraphData,
  getSnowflakeJobGraphDataSchema,
  GetSnowflakeJobItem,
  getSnowflakeJobItemSchema,
  GetSnowflakeJobRunGraphData,
  getSnowflakeJobRunGraphDataSchema,
  GetSnowflakeJobRunItem,
  getSnowflakeJobRunItemSchema,
  GetSnowflakeJobSummary,
  getSnowflakeJobSummarySchema,
  GetSnowflakeJobTaskItem,
  getSnowflakeJobTaskItemSchema,
  SnowflakeJobFilters,
  snowflakeJobFiltersSchema,
  SnowflakeJobSettings,
  snowflakeJobSettingsSchema,
  QueryAllCSVFilters,
  QueryGroupsFilters,
  QueryJobExecutionsFilters,
  QueryJobsFilters,
  QueryTextResponse,
} from "../pages/Query/types";

import { APIClient } from "./api_helper";
import {
  GetOpportunitiesDynamicFilterResponse,
  GetOpportunitiesDynamicFilterResponseSchema,
  GetTableLastRefreshedResponse,
  GetTableLastRefreshedResponseSchema,
  GetTenantInfoResponse,
  pageSchema,
  tenantInfoSchema,
  UrlResponse,
  urlResponse,
  UserLoginOutResponse,
  userLoginOutSchema,
} from "./types";
import { downloadBlob } from "./utils";
import {
  FilterType,
  SavedFilter,
  savedFilterSchema,
} from "@/Components/Tags/types";
import { z } from "zod";
import {
  GetAllUserGroupsResponseSchema,
  UploadUserGroupResponse,
  UploadUserResponse,
  UserGroupWithMembersAndRulesZodSchema,
} from "@/pages/settings/components/userGroups/types";
import { User } from "@/types";
import { TableDataFilters } from "@/pages/Datasets/types";
import { WarehouseFilters } from "@/pages/Warehouse/types";
import { RolesTableFilters, UsersTableFilters } from "@/pages/Governance/types";

export const api = new APIClient();

// Summary v2
export const getCostBreakdown = <R>(
  path: string,
  params: SummaryCostGraphRequest
) => api.get<R>(`/summary/v2/cost/${path}`, params);

export const getSavingsBreakdown = (
  path: string,
  params: SummaryCostGraphRequest
) =>
  api.get<
    | AutoTuneSavingsSummaryResponse
    | OverallSavingsResponse
    | OverallSavingsEstimateResponse
    | AssistedSavingsResponse
  >(`/summary/v2/savings/${path}`, params);

export const getSummaryPageOpportunities = (params: OpportunitiesSummary) =>
  api.get<GetSummaryOpportunitiesDataResponse[]>(
    "/summary/v2/opportunities",
    params
  );

export const getOpportunities = (data: GetOpportunitiesDataRequest) =>
  api.get<Page<GetOpportunitiesDataResponse>>("/opportunities", data);

export const getGroupedOpportunities = (data: GetOpportunitiesDataRequest) =>
  api.get<Page<GetOpportunitiesDataResponse>>("/opportunities/grouped", data);

export const updateOpportunities = (data: UpdateOpportunitiesDataRequest) =>
  api.post<UpdateOpportunitiesDataResponse>("/opportunities/", data);

export const getOpportunitiesSummary = (data: GetOpportunitiesSummaryRequest) =>
  api.get<GetOpportunitiesSummaryResponse[]>("/opportunities/summary", data);

export const getOpportunitiesResourceNameFilters = (
  data: GetOpportunitiesResourceNameFilterRequest
) =>
  api.get<GetOpportunitiesDynamicFilterResponse[]>(
    "/opportunities/resource_name_filter",
    data,
    10000,
    GetOpportunitiesDynamicFilterResponseSchema
  );

export const getOpportunitiesUserFilters = (
  data: GetOpportunitiesUserFilterRequest
) =>
  api.get<GetOpportunitiesDynamicFilterResponse[]>(
    "/opportunities/filters/user",
    data,
    10000,
    GetOpportunitiesDynamicFilterResponseSchema
  );

export const getOpportunitiesWarehouseFilters = (
  data: GetOpportunitiesWarehouseFilterRequest
) =>
  api.get<GetOpportunitiesDynamicFilterResponse[]>(
    "/opportunities/filters/warehouse",
    data,
    10000,
    GetOpportunitiesDynamicFilterResponseSchema
  );

export const getOpportunityNameFilters = () =>
  api.get<FilterResponse[]>("/opportunities/filters/opportunity_name", {});

export const getOpportunitiesResource = (
  data: GetOpportunitiesResourceRequest
) => api.get<GetOpportunitiesResourceResponse>("/opportunities/resource", data);

export const getOpportunitiesRedirection = (
  data: GetOpportunitiesRedirectionRequest
) =>
  api.get<GetOpportunitiesRedirectResponse>("/opportunities/redirection", data);

export const getOpportunityUpdates = (data: GetOpportunityUpdatesRequest) =>
  api.get<GetOpportunityUpdatesResponse[]>("/opportunities/updates", data);

export const getQuerySqlLineageById = (
  id: string,
  params: Record<string, string>
) =>
  api.get<GetQuerySQLLineageResponse>(
    `/query/v2/sql_lineage/${id}`,
    params,
    60000
  );

export const getQueryGroupSqlLineageById = (
  id: string,
  params: Record<string, string>
) =>
  api.get<GetQuerySQLLineageResponse>(
    `/query/v2/sql_lineage_group/${id}`,
    params,
    60000
  );

export const getQueryGroupDetailsByHash = <T>(
  hash: string,
  params: Record<string, string>
) => api.get<T>(`/query/v2/query_group/stats/${hash}`, params);

export const getQueryTextByHash = (hash: string) =>
  api.get<QueryTextResponse>(`/query/v2/query_group/query_text/${hash}`, {});

export const getTenantInfo = () =>
  api.get<GetTenantInfoResponse>(
    `/auth/tenant-info`,
    {},
    10000,
    tenantInfoSchema
  );

export const getSSOUserInfo = () =>
  api.get<User>("/auth/supertokens_user_info", {});

export const postRegisterSSO = (data: {
  firstName: string;
  lastName: string;
}) => api.post<User>("/auth/register-sso", data);
export const updateTenantInfo = (data: Partial<GetTenantInfoResponse>) =>
  api.update<{ ok: boolean }>(`/auth/tenant-info`, data, tenantInfoSchema);

export const postSendDatamateRequest = (requestType: string) =>
  api.post<{ ok: boolean }>("/notification/send_datamate_request", {
    request_type: requestType,
  });

export const getExportLineage = (params: { page: number; size: number }) =>
  api.get<Page<GetExportLineageItem>>(`/dbt/v4/export-lineage`, params);

export const getExportLineageItem = (id: string) =>
  api.get<GetExportLineageItem>(`/dbt/v4/export-lineage/${id}`, {});

export const getDownloadUrl = (s3_path: string) =>
  api.post<UrlResponse>(
    "/dbt/v1/download_signed_url",
    { s3_path },
    urlResponse
  );

export const getSavedFilters = (data: {
  applicableFor: string;
  type: FilterType;
}) => {
  return api.get<{ presets: SavedFilter[] }>(
    `/preset`,
    { applicable_for: data.applicableFor, type: data.type },
    0,
    z.object({
      presets: z.array(savedFilterSchema.extend({ id: z.unknown() })),
    })
  );
};

export const createFilter = (data: Omit<SavedFilter, "id">) => {
  return api.post<{
    preset_id: string;
  }>(`/preset`, data);
};

export const updateFilter = (data: SavedFilter) => {
  return api.update<{
    preset_id: string;
  }>(`/preset/${data.id}`, data);
};

export const deleteFilter = (id: string) => {
  return api.delete(`/preset/${id}`, {});
};

export const getLastTableRefreshedTime = (table_rk: string) =>
  api.get<GetTableLastRefreshedResponse>(
    `/tables/last_refreshed/?table_rk=${table_rk}`,
    {},
    10000,
    GetTableLastRefreshedResponseSchema
  );

export const regenerateToken = () =>
  api.post<UserLoginOutResponse>(
    "/auth/regenerate_token",
    undefined,
    userLoginOutSchema
  );

export const downloadAutoTuneHistoryCSV = (
  warehouse_rk: string,
  params: { period: string }
) =>
  api
    .get(`/warehouse/audit-events/csv/${warehouse_rk}`, {
      params,
      responseType: "blob", // Important! It won't download the file if responseType is not set to blob
    })
    .then((blob) => {
      downloadBlob(blob as Blob);
      return true;
    });

const downloadCSV = (endpoint: string, params: Record<string, any>) =>
  api
    .get(endpoint, params, undefined, undefined, {
      responseType: "blob",
      headers: {
        Accept: "text/csv",
      },
    })
    .then((blob) => {
      downloadBlob(blob as Blob);
      return true;
    });

export const downloadAllTableDataCSV = (params: TableDataFilters) =>
  downloadCSV(`/tables/tabledata`, params);

export const downloadWarehouseCSV = (params: WarehouseFilters) =>
  downloadCSV(`/warehouse`, params);

export const downloadAllQueryCSV = (params: QueryAllCSVFilters) =>
  downloadCSV(`/query/v2/download/`, params);

export const downloadAllQueryGroupsCSV = (params: QueryGroupsFilters) =>
  downloadCSV(`/query/v2/groups/`, params);

export const downloadAllQueryJobsCSV = (params: QueryJobsFilters) =>
  downloadCSV(`/query/jobs/`, params);

export const downloadAllQueryJobExecutionsCSV = (
  job_id: string,
  params: QueryJobExecutionsFilters
) => downloadCSV(`/query/jobs/executions/${job_id}`, params);

export const downloadUsersCSV = (params: UsersTableFilters) =>
  downloadCSV(`/governance/users`, params);

export const downloadRolesCSV = (params: RolesTableFilters) =>
  downloadCSV(`/governance/roles`, params);

export const downloadOpportunitiesCSV = (params: OpportunitiesFilters) =>
  downloadCSV(`/opportunities/`, params);

export const downloadGroupedOpportunitiesCSV = (
  params: GroupedOpportunitiesFilters
) => downloadCSV(`/opportunities/grouped/`, params);

export const downloadExecutionHistoryCSV = (all_time: string) =>
  downloadCSV(`/events/execution_history/`, {
    all_time,
  });


export const getUserGroups = ({
  search = "",
  page = 1,
}: {
  search?: string;
  page?: number;
}) =>
  api.get<z.infer<typeof GetAllUserGroupsResponseSchema>>(
    "/groups/",
    { search, page, size: 50 },
    0,
    GetAllUserGroupsResponseSchema
  );

export const getUserGroupDetails = (id: string) =>
  api.get<z.infer<typeof UserGroupWithMembersAndRulesZodSchema>>(
    `/groups/${id}`,
    {},
    0,
    UserGroupWithMembersAndRulesZodSchema
  );

export const uploadUserGroups = (data: FormData) => {
  return api.post<UploadUserGroupResponse>(
    "/groups/group_rule_file",
    data,
    undefined,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const uploadUsersToGroups = (data: FormData) => {
  return api.post<UploadUserResponse>(
    "/groups/user_group_file",
    data,
    undefined,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const deleteRuleInUserGroup = (ruleId: string, groupId: string) => {
  return api.delete(`/groups/${groupId}/rules/${ruleId}`, {});
};

export const deleteUserInUserGroup = (userId: string, groupId: string) => {
  return api.delete(`/groups/${groupId}/users/${userId}`, {});
};

export const deleteUserGroup = (groupId: string) => {
  return api.delete(`/groups/${groupId}`, {});
};

export const getSnowflakeJobs = (params: any) =>
  api.get<Page<GetSnowflakeJobItem>>(
    "/query/v2/snowflake_jobs",
    params,
    0,
    pageSchema(getSnowflakeJobItemSchema)
  );

export const getSnowflakeJobRuns = (id: string, params: any) =>
  api.get<Page<GetSnowflakeJobRunItem>>(
    `/query/v2/snowflake_jobs/runs/${id}`,
    params,
    0,
    pageSchema(getSnowflakeJobRunItemSchema)
  );

export const getSnowflakeJobRunTasks = (
  workflow_id: string,
  run_id: string,
  params: any
) =>
  api.get<Page<GetSnowflakeJobTaskItem>>(
    `/query/v2/snowflake_jobs/tasks/${workflow_id}/${run_id}`,
    params,
    0,
    pageSchema(getSnowflakeJobTaskItemSchema)
  );

export const getSnowflakeJobLatestRunTasks = (
  workflow_id: string,
  params: any
) =>
  api.get<GetSnowflakeJobTaskItem[]>(
    `/query/v2/snowflake_jobs/latest_run/${workflow_id}`,
    params,
    0,
    z.array(getSnowflakeJobTaskItemSchema)
  );

export const postSnowflakeJobRunGraphData = (
  workflow_id: string,
  params: { aggregate_by: "app_id" | "project_id" }
) =>
  api.post<GetSnowflakeJobRunGraphData[]>(
    `/query/v2/snowflake_jobs/graph_data/${workflow_id}`,
    params,
    z.array(getSnowflakeJobRunGraphDataSchema)
  );

export const getSnowflakeJobGraphData = (params: any) =>
  api.get<GetSnowflakeJobGraphData[]>(
    `/query/v2/snowflake_jobs/graph_data`,
    params,
    0,
    z.array(getSnowflakeJobGraphDataSchema)
  );

export const getSnowflakeJobOpportunities = (id: string, params: any) =>
  api.get<GetOpportunitiesDataResponse[]>(
    `/query/v2/snowflake_jobs/opportunities/${id}`,
    params
  );

export const getSnowflakeJobsSummary = (id: string, params: any) =>
  api.get<GetSnowflakeJobSummary>(
    `/query/v2/snowflake_jobs/summary/${id}`,
    params,
    0,
    getSnowflakeJobSummarySchema
  );

export const getSnowflakeJobSettings = () =>
  api.get<SnowflakeJobSettings>(
    "/query/v2/snowflake_jobs/settings",
    {},
    0,
    snowflakeJobSettingsSchema
  );

export const postSnowflakeJobSettings = (data: SnowflakeJobSettings) =>
  api.post<SnowflakeJobSettings>(
    "/query/v2/snowflake_jobs/settings",
    data,
    snowflakeJobSettingsSchema
  );

export const getSnowflakeJobFilters = (params: any) =>
  api.get<SnowflakeJobFilters>(
    "/query/v2/snowflake_jobs/filters",
    params,
    0,
    snowflakeJobFiltersSchema
  );
