import Select, {
  StylesConfig,
  components,
  OptionProps,
  GroupBase,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { Label, Stack } from "../../index";
import {
  CheckBlueIcon,
  SelectCheckedIcon,
  SelectUncheckedIcon,
  UncheckIcon,
} from "@assets/icons";
import classes from "./select.module.scss";
import classNames from "classnames";

const { Option } = components;

export interface OptionType {
  label: string;
  value: string;
}

const IconOption = (
  props: OptionProps<OptionType, boolean, GroupBase<OptionType>> & {
    selectName: string;
    showOptionIcon?: boolean;
  }
) => {
  const {
    data: { label },
    isMulti,
    isSelected,
    showOptionIcon,
  } = props;

  return (
    <Option {...props}>
      <div className="flex items-center gap-2">
        <Label check={isMulti}>
          {!showOptionIcon ? null : (
            <span style={{ marginRight: 10 }}>
              {isSelected ? (
                isMulti ? (
                  <SelectCheckedIcon />
                ) : (
                  <CheckBlueIcon />
                )
              ) : isMulti ? (
                <SelectUncheckedIcon />
              ) : (
                <UncheckIcon />
              )}
            </span>
          )}
          {label}
        </Label>
      </div>
    </Option>
  );
};

type Props = Parameters<typeof Select>[0] & {
  isCreatable?: boolean;
  showOptionIcon?: boolean;
  label?: string;
  showDivider?: boolean;
  allowOptionWrap?: boolean;
  width?: number;
};
export const filterOption = (option: OptionType, inputValue: string): boolean =>
  (option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || [])
    .length > 0;

const AltimateSelect = (props: Props): JSX.Element => {
  const colourStyles: StylesConfig<OptionType> = {
    menu: (styles) => ({
      ...styles,
      margin: 0,
      borderRadius: "0 0 4px 4px",
      backgroundColor: "#fff",
      right: 0,
      width: "auto",
      minWidth: "100%",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isSelected ? "#247efe33" : "transparent",
      cursor: "pointer",
      color: isFocused || isSelected ? "#247efe" : "#8390a3",
      borderBottom: props.showDivider ? "1px solid #e0e0e0" : "none",
      whiteSpace: props.allowOptionWrap ? "" : "nowrap",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    input: (styles) => ({
      ...styles,
      color: "#171717",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#247efe",
    }),
    multiValue: (styles) => ({
      ...styles,
      color: "#171717",
      backgroundColor: "#d3e5ff",
      borderRadius: 26,
      padding: "0 6px",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "inherit",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "#ffffff",
      borderColor: "#c4cad2",
      color: "#4a4d51",
    }),
    valueContainer: (styles) => ({
      ...styles,
      maxWidth: "20em",
      overflow: "auto",
      maxHeight: "3em",
    }),
    container: (styles, cprops) => ({
      ...styles,
      // @ts-expect-error TODO fix this type
      ...props.styles?.container?.(styles, cprops),
      width: props.width ?? "auto",
      margin: 0,
    }),
  };
  const selectName = props.name ?? `select-${Math.random()}`;
  const selectedValue = !props.value
    ? null
    : props.isMulti
    ? (props.options as OptionType[])?.filter((o) =>
        (props.value as string[]).includes(o.value)
      )
    : (props.options as OptionType[])?.find((o) => o.value === props.value);

  if (props.isCreatable) {
    return (
      <Stack className={classNames(classes.altimateSelect, props.className)}>
        {props.label && <Label>{props.label}</Label>}
        <CreatableSelect<OptionType>
          {...props}
          value={selectedValue}
          menuPortalTarget={document.body}
          styles={colourStyles}
          classNamePrefix="altimate-select"
          // @ts-expect-error TODO fix this type
          components={{
            ...props.components,
            Option: (optionProps) => (
              // @ts-expect-error TODO fix this type
              <IconOption
                {...optionProps}
                showOptionIcon={props.showOptionIcon}
                selectName={selectName}
              />
            ),
          }}
        />
      </Stack>
    );
  }
  return (
    <Stack className={classNames(classes.altimateSelect, props.className)}>
      {props.label && <Label>{props.label}</Label>}
      <Select<OptionType>
        {...props}
        menuPortalTarget={document.body}
        value={selectedValue}
        styles={colourStyles}
        filterOption={filterOption}
        onChange={(updates, actionMeta) => {
          if (props.isMulti) {
            props.onChange?.(
              ((updates ?? []) as OptionType[])?.map((val) => val.value),
              actionMeta
            );
          } else {
            props.onChange?.((updates as OptionType).value, actionMeta);
          }
        }}
        classNamePrefix="altimate-select"
        // @ts-expect-error TODO fix this type
        components={{
          ...props.components,
          Option: (optionProps) => (
            // @ts-expect-error TODO fix this type
            <IconOption
              {...optionProps}
              showOptionIcon={props.showOptionIcon}
              selectName={selectName}
            />
          ),
        }}
      />
    </Stack>
  );
};

export default AltimateSelect;
