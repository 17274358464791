import "react-tooltip/dist/react-tooltip.css";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { getTenant } from "./helpers/tenant";
import { initializeSuperTokens, SupertokensProvider } from "./supertokens";
import * as Sentry from "@sentry/react";
import { ENV, SENTRY_DSN } from "./config";
import { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { setTenantHeader } from "./helpers/api_helper";
import AppProvider from "./modules/app/AppProvider";
import { SomethingWrong } from "./pages/SomethingWrong";
import { TeamMateProvider } from "./lib/altimate/altimate-components";
import { CopilotProvider } from "./pages/Copilot";
import { InsightProvider } from "./pages/Query/components";
import LocationTracker from "./modules/app/LocationTracker";
import ColumnPickerProvider from "./Components/Column/ColumnPickerProvider";
import CookieConsentBanner from "./Components/CookieConsentBanner/CookieConsentBanner";
import { RelativeComponentLoader } from "./Components/Loader";

const RoutesV0 = lazy(() => import("./route/Routes.v0"));
const RoutesV1 = lazy(() => import("./route/Routes.v1"));
import "./dayjsConfig";

initializeSuperTokens();

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // Captures all api errors, will noisy initially ex: login but we could filter out later
    onError: (error) => {
      Sentry.captureException(error);
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

if (ENV === "staging" || ENV === "production") {
  Sentry.init({
    environment: ENV,
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function App() {
  const tenant = getTenant();
  setTenantHeader(tenant);

  return (
    <div className="App">
      <SupertokensProvider>
        <AppProvider>
          <Sentry.ErrorBoundary fallback={<SomethingWrong />}>
            <QueryClientProvider client={queryClient}>
              <TeamMateProvider>
                <CopilotProvider>
                  <InsightProvider>
                    <BrowserRouter>
                      <LocationTracker />
                      <ColumnPickerProvider>
                        <Suspense
                          fallback={
                            <RelativeComponentLoader label="Loading..." />
                          }
                        >
                          <RoutesV1 />
                          <RoutesV0 />
                        </Suspense>
                      </ColumnPickerProvider>
                    </BrowserRouter>
                  </InsightProvider>
                </CopilotProvider>
              </TeamMateProvider>
            </QueryClientProvider>
          </Sentry.ErrorBoundary>
          <CookieConsentBanner />
        </AppProvider>
      </SupertokensProvider>
    </div>
  );
}

export default App;
